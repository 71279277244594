html,
body {
  height: 100vh;
  margin: auto;
  background-color: black;
}

#soon-text {
  position: absolute;
  display: inline-block;
  height: max-content;
  width: max-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  color: rgba(255, 255, 255, 0.5);
  animation: fade-in 5s ease-in-out;
}

#soon-text > span:first-child {
  font-size: 8vw;
  font-weight: bold;
}

#soon-text > span:last-child {
  position: absolute;
  font-size: 3vw;
  transform: translateY(2vw);
}

#hellbore-prison {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 250px;
  overflow: hidden;
  width: max-content;
  height: max-content;
  animation: fade-in 2s ease-in-out;
}

#hellbore-prison::after {
  box-shadow: inset 0px 0px 90px 60px black;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  border-radius: 250px;
  top: 0;
  width: 100%;
}

#hellbore {
  height: auto;
  width: 100vw;
  max-width: 900px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
